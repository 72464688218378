import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "h-full flex flex-col"
};
const _hoisted_2 = {
  class: ""
};
const _hoisted_3 = {
  class: "overflow-y-auto"
};
const _hoisted_4 = {
  class: "mt-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_drawer = _resolveComponent("el-drawer");
  return _openBlock(), _createBlock(_component_el_drawer, {
    class: "p-0 m-0",
    "with-header": false,
    modelValue: $data.isOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.isOpen = $event),
    "before-close": $options.close
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("header", _hoisted_2, [_renderSlot(_ctx.$slots, "header"), _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = (...args) => $options.close && $options.close(...args)),
      class: "w-7 h-7 flex items-center justify-center rounded-full cursor-pointer absolute top-2 right-2"
    }, _cache[2] || (_cache[2] = [_createElementVNode("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: "28px",
      height: "28px",
      viewBox: "0 0 24 24",
      fill: "none"
    }, [_createElementVNode("circle", {
      cx: "12",
      cy: "12",
      r: "10",
      stroke: "#6B727C",
      "stroke-width": "1.5"
    }), _createElementVNode("path", {
      d: "M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5",
      stroke: "#6B727C",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    })], -1)]))]), _createElementVNode("main", _hoisted_3, [_renderSlot(_ctx.$slots, "default")]), _createElementVNode("footer", _hoisted_4, [_renderSlot(_ctx.$slots, "footer")])])]),
    _: 3
  }, 8, ["modelValue", "before-close"]);
}