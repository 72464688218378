import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "p-3"
};
const _hoisted_2 = {
  class: "font-bold text-lg"
};
const _hoisted_3 = {
  class: "p-3 flex justify-end"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_button = _resolveComponent("el-button");
  const _component_ModalWindow = _resolveComponent("ModalWindow");
  return _openBlock(), _createBlock(_component_ModalWindow, {
    title: "User",
    ref: "user"
  }, {
    header: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("h1", _hoisted_2, _toDisplayString($options.pageTitle), 1)])]),
    default: _withCtx(() => [_createVNode(_component_el_form, {
      class: "p-5",
      "label-position": "top"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_row, {
        gutter: 20
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "Имя"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: $data.user.first_name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.user.first_name = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "Фамилия"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: $data.user.last_name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.user.last_name = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "Email"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              disabled: $props.userId !== 'new',
              modelValue: $data.user.email,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.user.email = $event)
            }, null, 8, ["disabled", "modelValue"])]),
            _: 1
          })]),
          _: 1
        }), $props.userId === 'new' ? (_openBlock(), _createBlock(_component_el_col, {
          key: 0,
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "Пароль"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: $data.user.password,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.user.password = $event),
              type: "password",
              "show-password": ""
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "Роль"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              class: "w-full",
              modelValue: $data.user.roles,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.user.roles = $event),
              multiple: "",
              "multiple-limit": 1
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.roles, role => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: role,
                  label: role,
                  value: role
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_3, [$props.userId !== 'new' && $data.user.status === 1 ? (_openBlock(), _createBlock(_component_el_button, {
      key: 0,
      onClick: $options.removeUser,
      disabled: $data.loading,
      plain: "",
      type: "danger"
    }, {
      default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode(" Заблокировать ")])),
      _: 1
    }, 8, ["onClick", "disabled"])) : _createCommentVNode("", true), $props.userId !== 'new' && $data.user.status !== 1 ? (_openBlock(), _createBlock(_component_el_button, {
      key: 1,
      onClick: $options.restoreUser,
      disabled: $data.loading,
      plain: "",
      type: "success"
    }, {
      default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode(" Разблокировать ")])),
      _: 1
    }, 8, ["onClick", "disabled"])) : _createCommentVNode("", true), _createVNode(_component_el_button, {
      onClick: $options.save,
      disabled: $data.loading,
      plain: "",
      type: "primary"
    }, {
      default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode(" Сохранить ")])),
      _: 1
    }, 8, ["onClick", "disabled"])])]),
    _: 1
  }, 512);
}