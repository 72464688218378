import "core-js/modules/es.array.push.js";
import { changeUserPassword, getUsers } from "@/api/users";
export default {
  data() {
    return {
      users: [],
      form: {
        id: null,
        password: null,
        confirmPassword: null
      },
      filters: {
        type: null,
        typeAccount: null
      },
      search: "",
      debounce: null,
      showingInputs: {
        password: false,
        confirmPassword: false
      },
      passwordChangeShow: false
    };
  },
  computed: {
    userId() {
      return this.$route.params.userId;
    },
    user() {
      return this.users.find(u => u._id === this.userId);
    }
  },
  async mounted() {
    await this.getUsers();
  },
  methods: {
    async getUsers() {
      let filters = this.getFilters();
      this.users = await getUsers(filters);
    },
    searchUsers() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.users = [];
        this.getUsers();
      }, 500);
    },
    getFilters() {
      let params = {};
      if (this.filters.typeAccount) {
        params.role = this.filters.typeAccount;
      }
      if (this.filters.type) {
        params.status = this.filters.type;
      }
      if (this.search) {
        params.search = this.search;
      }
      return params;
    },
    viewUser(row) {
      this.$router.push({
        name: "user-view",
        params: {
          userId: row._id
        }
      });
    },
    createUser() {
      this.$router.push({
        name: "user-view",
        params: {
          userId: "new"
        }
      });
    },
    tableRowClassName({
      row
    }) {
      if (row.status === -1) {
        return "warning-row";
      }
      return "";
    },
    changePasswordUser(id) {
      console.log(id);
      this.passwordChangeShow = true;
      this.form.id = id;
    },
    async updatePassword() {
      let data = await changeUserPassword(this.form.id, this.form.password);
      if (data._success) {
        this.passwordChangeShow = false;
        this.form.id = null;
        this.form.password = null;
      }
    }
  }
};