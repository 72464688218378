import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex items-center justify-between p-6 border-b"
};
const _hoisted_2 = {
  class: "mt-1 text-sm font-light text-gray-700"
};
const _hoisted_3 = {
  class: "flex items-center"
};
const _hoisted_4 = {
  class: "flex flex-wrap w-full justify-end gap-2 items-center"
};
const _hoisted_5 = {
  class: "m-6"
};
const _hoisted_6 = {
  class: "mt-3 text-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_table = _resolveComponent("el-table");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_dialog = _resolveComponent("el-dialog");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("header", _hoisted_1, [_createElementVNode("div", null, [_cache[7] || (_cache[7] = _createElementVNode("h1", {
    class: "text-3xl font-semibold"
  }, "Пользователи", -1)), _createElementVNode("div", _hoisted_2, _toDisplayString($data.users.length) + " шт ", 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_select, {
    onChange: $options.getUsers,
    modelValue: $data.filters.type,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.filters.type = $event),
    size: "large",
    style: {
      "width": "240px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_option, {
      value: null,
      label: "Все"
    }), _createVNode(_component_el_option, {
      value: 1,
      label: "Действующие"
    }), _createVNode(_component_el_option, {
      value: -1,
      label: "Удаленные"
    })]),
    _: 1
  }, 8, ["onChange", "modelValue"]), _createVNode(_component_el_input, {
    type: "text",
    modelValue: $data.search,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.search = $event),
    clearable: "",
    size: "large",
    onInput: $options.searchUsers,
    placeholder: "Введите для поиска",
    style: {
      "width": "240px"
    }
  }, null, 8, ["modelValue", "onInput"]), _createVNode(_component_el_select, {
    onChange: $options.getUsers,
    modelValue: $data.filters.typeAccount,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.filters.typeAccount = $event),
    size: "large",
    style: {
      "width": "240px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_option, {
      label: "Все",
      value: null
    }), _createVNode(_component_el_option, {
      label: "Верификатор",
      value: "verifier"
    }), _createVNode(_component_el_option, {
      label: "Разметчик",
      value: "annotator"
    }), _createVNode(_component_el_option, {
      label: "Админ",
      value: "admin"
    })]),
    _: 1
  }, 8, ["onChange", "modelValue"]), _createElementVNode("div", {
    onClick: _cache[3] || (_cache[3] = (...args) => $options.createUser && $options.createUser(...args)),
    class: "text-sm text-red-600 cursor-pointer w-32 items-center flex"
  }, " + Создать ")])])]), _createElementVNode("main", _hoisted_5, [_createVNode(_component_el_table, {
    onRowClick: $options.viewUser,
    border: "",
    data: $data.users,
    "row-class-name": $options.tableRowClassName
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      type: "index",
      label: "N"
    }), _createVNode(_component_el_table_column, {
      prop: "first_name",
      label: "Имя"
    }), _createVNode(_component_el_table_column, {
      prop: "last_name",
      label: "Фамилия"
    }), _createVNode(_component_el_table_column, {
      prop: "email",
      label: "Email"
    }), _createVNode(_component_el_table_column, {
      prop: "roles",
      label: "Роли"
    }), _createVNode(_component_el_table_column, {
      label: "Действия"
    }, {
      default: _withCtx(scope => [_createVNode(_component_el_button, {
        circle: "",
        onClick: _withModifiers($event => $options.changePasswordUser(scope.row._id), ["stop", "prevent"])
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [_createElementVNode("svg", {
          class: "ov-icon",
          "aria-hidden": "true",
          width: "18",
          height: "18",
          viewBox: "0 0 24 24",
          fill: "none",
          stroke: "#222F3D"
        }, [_createElementVNode("path", {
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          d: "M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
        })], -1)])),
        _: 2
      }, 1032, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["onRowClick", "data", "row-class-name"])]), _createVNode(_component_el_dialog, {
    modelValue: $data.passwordChangeShow,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $data.passwordChangeShow = $event),
    title: "Заменить пароль"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form, {
      "label-position": "top"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: "Введите пароль"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          type: "password",
          "show-password": "",
          modelValue: $data.form.password,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.form.password = $event)
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "Повторите пароль"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.form.confirmPassword,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.form.confirmPassword = $event),
          "show-password": "",
          type: "password"
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    }), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_button, {
      disabled: $data.form.password && $data.form.confirmPassword && $data.form.password !== $data.form.confirmPassword,
      onClick: $options.updatePassword,
      type: "primary"
    }, {
      default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("Поменять пароль")])),
      _: 1
    }, 8, ["disabled", "onClick"])])]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_router_view, {
    onRefetchUsers: $options.getUsers
  }, null, 8, ["onRefetchUsers"])], 64);
}