export default {
  popupController: null,
  data() {
    return {
      isOpen: true
    };
  },
  beforeUnmount() {
    this.$options.popupController = null;
  },
  methods: {
    async open() {
      let resolve;
      let reject;
      const popupPromise = new Promise((ok, fail) => {
        resolve = ok;
        reject = fail;
      });
      this.$options.popupController = {
        resolve,
        reject
      };
      this.isOpen = true;
      return popupPromise;
    },
    close() {
      this.$options.popupController.resolve({
        action: "cancel"
      });
      this.isOpen = false;
    }
  }
};