import "core-js/modules/es.array.push.js";
import ModalWindow from "@/components/ModalWindow";
import { getUser as _gertUser, getRoles as _getRoles, createUser as _createUser, updateUser as _updateUser } from "@/api/users";
export default {
  components: {
    ModalWindow
  },
  emits: ["refetch-users", "update:email"],
  props: {
    userId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      roles: [],
      loading: false,
      user: {
        email: null,
        password: null,
        roles: ["annotator"],
        first_name: null,
        last_name: null,
        pd: false
      }
    };
  },
  computed: {
    pageTitle() {
      return this.userId === "new" ? "Создание пользователя" : "Редактирование пользователя";
    }
  },
  async mounted() {
    await this.getRoles();
    if (this.userId !== "new") {
      await this.getUser();
    }
    await this.openModalWindow();
  },
  methods: {
    async openModalWindow() {
      const res = await this.$refs["user"].open();
      this.$router.push({
        name: "users-list"
      });
      console.log(res);
    },
    async getUser() {
      this.loading = true;
      const user = await _gertUser(this.userId);
      this.loading = false;
      this.user = {
        ...this.user,
        ...user
      };
    },
    async getRoles() {
      this.roles = await _getRoles();
    },
    async createUser() {
      this.loading = true;
      const result = await _createUser(this.user);
      this.loading = false;
      this.postProcessing(result);
    },
    async updateUser() {
      this.loading = true;
      const result = await _updateUser(this.userId, this.user);
      this.loading = false;
      this.postProcessing(result);
    },
    async removeUser() {
      this.loading = true;
      const result = await _updateUser(this.userId, {
        ...this.user,
        status: -1
      });
      this.loading = false;
      this.postProcessing(result);
    },
    async restoreUser() {
      this.loading = true;
      const result = await _updateUser(this.userId, {
        ...this.user,
        status: 1
      });
      this.loading = false;
      this.postProcessing(result);
    },
    async save() {
      if (this.userId === "new") {
        await this.createUser();
      } else {
        await this.updateUser();
      }
    },
    postProcessing(result) {
      if (!result._success) {
        return;
      }
      this.$emit("refetch-users");
      this.$router.push({
        name: "users-list"
      });
    },
    changeUser(val) {
      this.$emit("update:email", val);
      console.log(val);
    }
  }
};